import React,{ useState,useEffect } from "react";
import Sale01 from '../components/sale/Sale01';
import axios from 'axios';
import ImageLoader from "../components/loader/loader";
import { Helmet } from 'react-helmet';
import Copy from 'clipboard-copy';
import { ToastContainer, toast } from 'react-toastify';

function PartnerAuthenticity(props) {
 
  const[loader,setLoader] = useState(false);
    
  
   const searchOffical = ()=>{

    const username = document.getElementById("username").value;

    setLoader(true)
    var data = JSON.stringify({
        "username": username
      });
      
      var config = {
        method: 'post',
        url: 'https://test.cetoex.org/api/officalVerification',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        
        if(response.data.errorcode === "X001")
        {
            
                document.getElementById("verify_text").innerHTML = '<div style="padding:12px;border:1px dashed #0380fd;"><p> <span style="color:#0380fd;font-size:18px;font-weight:bold;"> Listing Partner is Verified.</span><br/>Partner Name : '+response.data.partner_name+' <br/>Designation : '+response.data.designation+'<br/>Telegram Username : '+response.data.partner_usernmae+'<br/>Join Date : '+response.data.partner_date+'</p></div>';
        }
        else{
            
                document.getElementById("verify_text").innerHTML = '<div style="padding:12px;border:1px dashed #d9534f;"><p> <span style="color:#d9534f;font-size:18px;font-weight:bold;"> Listing Partner is invalid.</span><br/>Be Aware from fraudasters.</p></div>';
       
        }
        setLoader(false)


      })
      .catch(function (error) {
        console.log(error);
      });
      
   }

   

   
   

    return (
        <div>

       
           
            {
    loader && <ImageLoader/>
}

            <section className="faq">

                                               
<Helmet>
        <title>Check Official Authenticity | Cetoex</title>
        <meta name="description" content="Listing agents are not authorized to enter any contracts or accept any payments on behalf of CetoEX."/>
        <meta name="keywords" content="crypto market, crypto deposit history, cetoex, exchange, top ranking coin, top volume, newly listed, top gainers, top losers, cetoex exchange, spot, trading, crypto"/>
        <meta name="author" content="Cetoex"/>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        
        {/* Open Graph (OG) tags for sharing on social media */}
        <meta property="og:title" content="Check Offical Authenticity | Cetoex"/>
        <meta property="og:description" content="Listing agents are not authorized to enter any contracts or accept any payments on behalf of CetoEX."/>
        
        {/* Twitter card tags for sharing on Twitter */}
        <meta name="twitter:card" content="cetoex"/>
        <meta name="twitter:title" content="Check Offical Authenticity | Cetoex"/>
        <meta name="twitter:description" content="Listing agents are not authorized to enter any contracts or accept any payments on behalf of CetoEX."/>
       
      </Helmet>
      <ToastContainer position="bottom-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme="dark"/>


            <div className="container">
                <div className="row">
                <div className="col-md-12">
                    <div className="block-text center">
                    <h3 className="heading">CetoEX Official Verification</h3>
                    <p className="desc fs-20">Please make sure that you are visiting <span style={{color:"#3773ff"}}>https://www.cetoex.com</span></p>
                    </div>
                </div>
                <div className="col-md-12">
                <div className="field1" id="searchform">
                   <input type="text"   placeholder="Enter Offical Telegram Username" id="username"/>
                    <button type="button" id="search" onClick={searchOffical}>
                     <span className="fa fa-search"></span>     Offical Verification
                     </button>
                </div>

<div className="field1" id="searchform">
<div id="verify_text" style={{margin:"auto"}} ></div>
</div>




<div className="payment-addresses">
  

    <div className="address-container" style={{background: "#f8f9fa", padding: "25px", borderRadius: "10px", marginBottom: "30px"}}>
     
     
    <div className="danger-text" style={{background: "#fff3cd", color: "#856404", padding: "15px", borderRadius: "6px"}}>
        <strong>⚠️ Warning:</strong> We are not responsible for any losses if payments are sent to unauthorized addresses or agents. Always verify you are using these official addresses.
    </div>


<p style={{ color: "#856404", padding: "15px"}} >

Please note that CetoEX works with Listing Partners to identify quality tokens and assets. However, Listing Partners are not employees or representatives of CetoEX, and are not authorized to enter any contracts or accept any payments on behalf of CetoEX.<br/>

This account may be owned and operated by an external party not affiliated with CetoEX. You should only trust our official account ending in cetoex.com to transmit legally binding agreement, information about your assets and wallet addresses, or other confidential information.
    </p>
     

        <div className="address-item" style={{marginBottom: "15px"}}>
            <h6 style={{color: "#3773ff", marginBottom: "6px",fontSize:"13px"}}>ERC20,BEP20 Address<span style={{fontSize:"8px"}}> (Tap to copy)</span></h6>
           
            <div  onClick={()=>{
              Copy("0x1b38A3ec0138618f9ab121d2113ae059f8168d6C");
         

              toast.success("BEP20,ERC20 Address Copied", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });


            }} style={{background: "#fff", padding: "8px", borderRadius: "6px", border: "1px solid #dee2e6"}}>
            <div className="form-group">
             <p style={{fontSize:"12px"}}>{"0x1b38A3ec0138618f9ab121d2113ae059f8168d6C"}</p>
               </div>
            </div>


        </div>

        <div className="address-item" style={{marginBottom: "15px"}}>
            <h6 style={{color: "#3773ff", marginBottom: "6px",fontSize:"13px"}}>Solana (SOL) Address<span style={{fontSize:"8px"}}> (Tap to copy)</span></h6>
           
            <div  onClick={()=>{
              Copy("C5CUxvFFDcR8E9LLPz3PZG7V8CJBXEornQ7ygs8vZpig");
         

              toast.success("SOL Address Copied", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });


            }}
            
            style={{background: "#fff", padding: "8px", borderRadius: "6px", border: "1px solid #dee2e6"}}>
            <div className="form-group">
             <p style={{fontSize:"12px"}}>{"C5CUxvFFDcR8E9LLPz3PZG7V8CJBXEornQ7ygs8vZpig"}</p>
               </div>
            </div>
        </div>


        <div className="address-item" style={{marginBottom: "15px"}}>
            <h6 style={{color: "#3773ff", marginBottom: "6px",fontSize:"13px"}}>Tron (TRX) Address<span style={{fontSize:"8px"}}> (Tap to copy)</span></h6>
           
            <div  onClick={()=>{
              Copy("THcZGN9TqFAcakKxpXwUFtULVUjA8anh4a");
         

              toast.success("TRX Address Copied", {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                });


            }}
            style={{background: "#fff", padding: "8px", borderRadius: "6px", border: "1px solid #dee2e6"}}>
            <div className="form-group">
             <p style={{fontSize:"12px"}}>{"THcZGN9TqFAcakKxpXwUFtULVUjA8anh4a"}</p>
               </div>
            </div>
        </div>





        
    </div>

    
</div>

                </div>
                </div>
            </div>
            </section>

            <Sale01 />
            
        </div>
    );
}

export default PartnerAuthenticity;